<template>
  <div class="container-fluid">
    <h1 class="text-center">Categories</h1>

    <!-- Category List -->
    <div class="row category-row" v-if="categories">
      <app-categories class="col-12 col-sm-6 col-md-4 col-lg-3 category-wrapper"
                      v-for="category in categories"
                      :key="category.category_id"
                      :category="category">
      </app-categories>
    </div>
    <div v-else>
      <no-content v-if="!loading">
        That category is still not available. Here are some suggestions:
      </no-content>
    </div>

    <div class="row"
         v-if="categoriesLastPage &&
          categoriesCurrentPage !== categoriesLastPage">
      <div class="col text-center">
        <b-button class="btn btn-h3-small btn-h3-blue text-center mt-3 mb-3"
                  type="button"
                  @click="loadMoreCategories">
          Load More
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
  const AppCategories = () =>
  import('@/components/user/common/AppCategories.vue');
  const NoContent = () => import('@/components/user/common/NoContent');

  export default {
    name       : 'Categories',
    components : {
      AppCategories,
      NoContent,
    },
    data() {
      return {
        categories            : [],
        categoriesPerPage     : 8,
        categoriesCurrentPage : 0,
        categoriesLastPage    : 0,
        loading               : true,
      }
    },
    mounted() {
      this.getCategories(0);
      this.$analytics.fbq.event('PageView', {
        'page'     : 'user-categories',
        'platform' : 'website',
        'user_id'  : this.$store.getters['user/user'].user_id,
      });
    },
    methods : {

      /**
       * Get All Categories created via Admin
       *
       * @param currentPage Pagination's current page
       */
      getCategories(currentPage = this.categoriesCurrentPage) {
        this.loading = true;
        this.$http.get('api/categories', {
          params : {
            page    : currentPage + 1,
            perPage : this.categoriesPerPage,
          },
        }).then((response) => {
          this.loading = false;
          this.categoriesCurrentPage = response.data.currentPage;
          this.categoriesLastPage = response.data.lastPage;

          if (this.categoriesCurrentPage > 1) {
            const arr1 = this.categories;
            const arr2 = response.data.data;

            this.categories = arr1.concat(arr2);
          } else this.categories = response.data.data;

        }).catch(() => {
          this.loading = false;
          this.categories = null;
        });
      },

      /**
       * Load More Categories
       */
      loadMoreCategories() {
        this.getCategories();
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/components/user/categories";
</style>
